import React, { useState, useEffect } from "react";
import { GetProcedures } from "../Form/Requests";
import ProcedureInput from "./ProcedureInput";

const Procedures = ({ envImpact, envImpactAux, setEnvImpactAux }) => {
  const [checked, setChecked] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const [multiProcedures, setMultiProcedures] = useState([]);
  const [multiProceduresAux, setMultiProceduresAux] = useState([]);
  const [counterProcedures, setCounterProcedures] = useState(0);

  useEffect(() => {
    const formattedProcedures = envImpact.multi_procedures_attributes.reduce((acc, procedure, index) => {
      acc[index] = {
        indexProcedure: index + 1,
        procedure_id: procedure.procedure_id,
        processable_id: procedure.processable_id,
        id: procedure.id,
        _destroy: false,
      };
      return acc;
    }, []);
    setMultiProceduresAux(formattedProcedures)
    setCounterProcedures(envImpact.multi_procedures_attributes.length)
  }, [envImpact])

  const handleChange = () => {
    if (checked) {
      const updatedImpact = { ...envImpactAux };
      updatedImpact.all_procedures = false;
      updatedImpact.multi_procedures_attributes =
        multiProcedures;
      setEnvImpactAux(updatedImpact);
    } else {
      const updatedImpact = { ...envImpactAux };
      updatedImpact.all_procedures = true;
      updatedImpact.multi_procedures_attributes = {};
      setEnvImpactAux(updatedImpact);
    }
    setChecked(!checked);
  };

  const addInput = () => {
    const localCounter = counterProcedures + 1;
    const newProcedure = {
      indexProcedure: localCounter,
      procedure_id: procedures[0].id,
    };
    setCounterProcedures(counterProcedures + 1);
    setMultiProceduresAux([...multiProceduresAux, newProcedure]);
  };

  const deleteInput = (e) => {
    const item = multiProceduresAux.find(item => item.indexProcedure === e);
    if (!!item.id) {
      const indexToUpdate = multiProceduresAux.findIndex(item => item.indexProcedure === e);
      const newObj = { ...multiProceduresAux[indexToUpdate], _destroy: true}
      setMultiProceduresAux(prevState => {
        const newState = [...prevState];
        newState[indexToUpdate] = newObj;
        return newState;
      });
    } else {
      const updatedProcedures = multiProceduresAux.filter(
        (proc) => proc.indexProcedure !== e
      );
      const reindexedProcedures = updatedProcedures.map((proc, index) => ({
        ...proc,
        indexProcedure: index,
      }));
      setMultiProceduresAux(reindexedProcedures);
    }
  };

  const handleSelect = (id, e) => {
    const { value } = e.target;
    setMultiProceduresAux((prevMultiProceduresAux) =>
      prevMultiProceduresAux.map((item) =>
        item.indexProcedure === id ? { ...item, procedure_id: value } : item
      )
    );
  };

  useEffect(() => {
    GetProcedures().then((data) => {
      setProcedures(data);
    });
  }, []);

  useEffect(() => {
    const formattedProcedures = multiProceduresAux.reduce(
      (acc, procedure, index) => {
        acc[index] = {
          id: procedure?.id || null,
          procedure_id: procedure?.procedure_id,
          processable_id: procedure?.processable_id || null,
          processable_type: "EnvironmentalImpact",
          _destroy: procedure?._destroy || false,
        };
        return acc;
      },
      {}
    );
    setMultiProcedures(formattedProcedures);
  }, [multiProceduresAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpactAux };
    updatedImpact.multi_procedures_attributes =
      multiProcedures;
    setEnvImpactAux(updatedImpact);
  }, [multiProcedures]);

  return (
    <div className="card-body">
      <div className="form-row">
        <div className="col-md-12 justify-content-between">
          <h6>Procesos Asociados:</h6>
          <div className="col-md-12">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input ml-3"
                checked={checked}
                onChange={handleChange}
              />
              <label
                className="custom-control-label mr-3"
                onClick={() => handleChange()}
              >
                Todos Los procesos
              </label>
            </div>
          </div>
        </div>
        {!checked && (
          <div className="col-md-12">
            <div className="form-group mb-3">
              <span> Proceso </span>
              {multiProceduresAux.map((input) => (
                <ProcedureInput
                  key={input.indexProcedure}
                  procedures={procedures}
                  deleteInput={deleteInput}
                  idInput={input.indexProcedure}
                  idProcedure={input.procedure_id}
                  handleSelect={handleSelect}
                  input={input}
                />
              ))}
              <button
                type="button"
                className="btn btn-info btn-sm mb-2"
                onClick={addInput}
              >
                <i className="fas fa-plus mr-1"></i>
                Agregar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Procedures;
