import { Controller } from 'stimulus'

export default class extends Controller {
  static targets=['checkField']
  connect() {
    //console.log('conectado')
  }
  onChange() {
    const value = $(this.checkFieldTarget).prop('checked')
    const url = this.data.get('url')
    const name = this.data.get('name')
    const id = this.data.get('id')
    $.post(`${url}?item=${id}&value=${value}&name=${name}`)
  }
}
