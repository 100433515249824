import React, { useEffect, useState } from 'react'
import Indicator from './Indicator'

const Indicators = ({indicatorsList, permissions, user, portal}) => {
  const [showSection, setShowSection] = useState('actives')
  //const [loading, setLoading] = useState(false)
  const [indicators, setIndicators] = useState(indicatorsList.filter(indicator => indicator.finished === false && indicator.state === null))
  const [actives, setActives] = useState(indicatorsList.filter(indicator => indicator.finished === false && indicator.state === null))
  const [obsoletes, setObsoletes] = useState(indicatorsList.filter(indicator => indicator.finished === false && indicator.state === 'obsolete'))
  const [finished, setFinished] = useState(indicatorsList.filter(indicator => indicator.finished === true))

  const pressActives = () => {
    setShowSection('actives')
    setIndicators(actives)
  }
  const pressObsoletes = () => {
    setShowSection('obsoletes')
    setIndicators(obsoletes)
  }
  const pressFinished = () => {
    setShowSection('finished')
    setIndicators(finished)
  }


  return (
    <React.Fragment>
      <div className='card-group'>
        <ul className='nav nav-tabs' role="tablist">
          <li className='nav-item'>
            <button className={`nav-link ${showSection === 'actives' ? 'active text-secondary' : 'bg-white text-primary'}`}
                    role="tab"
                    onClick={() => pressActives('actives')}>
              {`Indicatores Activos (${actives.length})`}
            </button>
          </li>
          <li className='nav-item'>
            <button className={`nav-link ${showSection === 'obsoletes' ? 'active text-secondary' : 'bg-white text-primary'}`}
                    role="tab"
                    onClick={() => pressObsoletes('obsoletes')}>
              {`Indicadores Obsoletos (${obsoletes.length})`}
            </button>
          </li>
          <li className='nav-item'>
            <button className={`nav-link ${showSection === 'finished' ? 'active text-secondary' : 'bg-white text-primary'}`}
                    role="tab"
                    onClick={() => pressFinished('finished')}>
              {`Indicadores Finalizados (${finished.length})`}
            </button>
          </li>
        </ul>
      </div>
      <div className='card border-light'>
        {
          indicators.map((indicator, index)=> {
            return (
              <React.Fragment key={indicator.id}>
                <Indicator indicator={indicator} user={user} permissions={permissions} portal={portal}/>
              </React.Fragment>
            )
          })
        }
      </div>
    </React.Fragment>
  )
}

export default Indicators