import React, { useEffect, useState } from "react";

const Table = (props) => {
  const [title, setTitle] = useState(props?.info_matrix[0]?.name || "");
  const [infoMatrix, setInfoMatrix] = useState(props.info_matrix);
  const [infoMatrixFormatted, setInfoMatrixFormatted] = useState([]);

  useEffect(() => {
    const groupByActivityId = (data) => {
      const groupedData = {};
      data.forEach((obj) => {
        if (!groupedData[obj.activity_id]) {
          groupedData[obj.activity_id] = [];
        }
        groupedData[obj.activity_id].push(obj);
      });
      return Object.values(groupedData);
    };
    setInfoMatrixFormatted(groupByActivityId(infoMatrix));
  }, [infoMatrix]);

  return (
    <>
      <div className="card-header d-flex justify-content-between bg-dark position-sticky sticky-top text-white">
        <h5 className="card-title font-weight-bold mb-0 ml-xs-4">
          <a className="btn btn-link" href="/environmental_matrices">
            <i className="fas fa-arrow-left" />
          </a>
          {title}
        </h5>
      </div>
      <div className="card-body p-0">
        <table className="table table-bordered table-sm table-responsive vh-100">
          <thead>
            <tr>
              <th className="text-center align-middle" scope="col">
                Sede/Obra
              </th>
              <td className="text-center align-middle" scope="col" colSpan={5}>
                Pasto
              </td>
              <th className="text-center align-middle" scope="col">
                Fecha Elaboración
              </th>
              <td className="text-center align-middle" scope="col" colSpan={2}>
                2023-04-18 00:00:00 -0500
              </td>
            </tr>
            <tr>
              <th
                className="text-center align-middle"
                scope="col"
                rowSpan={2}
                style={{ minWidth: "200px" }}
              >
                Proceso
              </th>
              <th
                className="text-center align-middle"
                scope="col"
                rowSpan={2}
                style={{ minWidth: "300px" }}
              >
                Actividad
              </th>
              <th className="text-center align-middle" scope="col" colSpan={3}>
                Situación
              </th>
              <th className="text-center align-middle" scope="col" colSpan={2}>
                Aspecto Ambiental Asociado
              </th>
              <th className="text-center align-middle" scope="col" colSpan={2}>
                Impacto Ambiental
              </th>
              <th className="text-center align-middle" scope="col" colSpan={13}>
                Evaluación del impacto
              </th>
              <th className="text-center align-middle" scope="col" colSpan={3}>
                Riesgos y Oportunidades
              </th>
              <th className="text-center align-middle" scope="col" colSpan={2}>
                Indicador de significancia de Impactos ambientales
              </th>
              <th className="text-center align-middle" scope="col" colSpan={5}>
                Plan de gestión de aspectos e impactos ambientales
              </th>
            </tr>
            <tr>
              <th className="text-center align-middle">N</th>
              <th className="text-center align-middle">A</th>
              <th className="text-center align-middle">E</th>
              <th
                className="text-center align-middle"
                scope="col"
                rowSpan={2}
                style={{ minWidth: "250px" }}
              >
                Aspecto Ambiental
              </th>
              <th
                className="text-center align-middle"
                scope="col"
                rowSpan={2}
                style={{ minWidth: "300px" }}
              >
                Descripción del Aspecto Ambiental
              </th>
              <th
                className="text-center align-middle"
                scope="col"
                rowSpan={2}
                style={{ minWidth: "150px" }}
              >
                Recurso Afectado
              </th>
              <th
                className="text-center align-middle"
                scope="col"
                rowSpan={2}
                style={{ minWidth: "300px" }}
              >
                Impacto generado
              </th>
              <th className="text-center align-middle">Naturaleza +/-</th>
              <th className="text-center align-middle">Intensidad</th>
              <th className="text-center align-middle">Extensión</th>
              <th className="text-center align-middle">Momento</th>
              <th className="text-center align-middle">Persistencia</th>
              <th className="text-center align-middle">Reversibilidad</th>
              <th className="text-center align-middle">Sinergia</th>
              <th className="text-center align-middle">Acumulación</th>
              <th className="text-center align-middle">Efecto</th>
              <th className="text-center align-middle">Periodicidad</th>
              <th className="text-center align-middle">Recuperabilidad</th>
              <th className="text-center align-middle">Valor Significancia</th>
              <th className="text-center align-middle">
                Nivel de Significancia
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "200px" }}
              >
                Riesgo asociado
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "200px" }}
              >
                Descripción del riesgo asociado
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "200px" }}
              >
                Descripción de la oportunidad asociada
              </th>
              <th className="text-center align-middle">
                Impacto Significativo
              </th>
              <th className="text-center align-middle">
                Impacto no Significativo
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "300px" }}
              >
                Acciones o control
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "100px" }}
              >
                Responsable
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "100px" }}
              >
                Fecha Inicio
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "100px" }}
              >
                Fecha Fin
              </th>
              <th
                className="text-center align-middle"
                style={{ minWidth: "250px" }}
              >
                Evidencia
              </th>
            </tr>
          </thead>
          <tbody>
            {infoMatrixFormatted.map((activity) => (
              <tr>
                <td className="text-center align-middle">
                  {activity[0].procedures}
                </td>
                <td className="text-center align-middle">
                  {activity[0].activity_title}
                </td>
                <td className="text-center align-middle">
                  {activity[0].situation === 1 ? "x" : ""}
                </td>
                <td className="text-center align-middle">
                  {activity[0].situation === 2 ? "x" : ""}
                </td>
                <td className="text-center align-middle">
                  {activity[0].situation === 3 ? "x" : ""}
                </td>
                <td className="text-center align-middle">
                  {activity[0].aspect_title}
                </td>
                <td className="text-center align-middle">
                  {activity[0].aspect_description}
                </td>
                <td className="text-center align-middle">
                  {activity[0].resources}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
