import { Controller }  from 'stimulus'


export default class extends Controller {

  static targets = ['answer', 'false', 'true', 'selection', 'selection_false']
 

  connect() {
    this.onCheck();
  }

  onCheck ()
  {
    //console.log('Preguntas seleccion')
    const color = this.data.get('color')
    const color_ok = this.data.get('ok')
    var check = document.getElementById("boton");
    var texto = document.getElementById("texto");
    var selection = document.getElementsByName("survey_answer[yes_or_not]");
    var k = 0

    for (var x = 0; x < selection.length; x++) {
      if (selection[x].checked){
        k = k+1
      }
    }
    if (k>0)
    {
      check.style.display = ''
      texto.style.display = 'none'
    }
    else
    {
      check.style.display = 'none'
      texto.style.display = ''
    }
  }

}
