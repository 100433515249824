import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    this.onChange()
  }

  onChange(e) {
    if (e) e.preventDefault();
    const url =  this.data.get('url')
    const maintenance = this.data.get('maintenance')
    const target = this.data.get('target')
    var radio_selected = document.querySelector('input[name="show_equipament"]:checked');
    radio_selected = radio_selected ? radio_selected.id : 'name'
    $.get(url+'?element_id='+this.element.value+'&maintenance_id='+maintenance+'&show_by='+radio_selected).then((content) => {
      document.getElementById(target).innerHTML = content
    })
    if ($('#corrective_maintenance_correction_type_infrastructure').prop('checked')) {
      $('#equipmentFormDiv').hide()
    } else {
      $('#equipmentFormDiv').show()
    }
  }
}
