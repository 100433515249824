import React, { useEffect, useState } from "react";
import Aspect from "./Aspect";
import { GetEnvironmentalAspects } from "../Form/Requests";

const Activity = ({
  indexActivity,
  deleteActivity,
  activitiesAux,
  setActivitiesAux,
  activities,
  setActivities,
  envImpact,
  setEnvImpactAux,
  activity,
  envImpactAux,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [aspects, setAspects] = useState([]);
  const [envAspectsAux, setEnvAspectsAux] = useState([]);
  const [envAspects, setEnvAspects] = useState([]);
  const [envAspectsCounter, setEnvAspectsCounter] = useState(0);

  const handleChange = (e) => {
    const { value } = e.target;
    const updatedActivities = [...activitiesAux];
    updatedActivities[indexActivity] = {
      ...updatedActivities[indexActivity],
      activityInput: value,
    };
    setActivitiesAux(updatedActivities);
  };

  const handleRadioChange = (value) => {
    const updatedActivities = [...activitiesAux];
    updatedActivities[indexActivity] = {
      ...updatedActivities[indexActivity],
      situationInput: value,
    };
    setActivitiesAux(updatedActivities);
  };

  useEffect(() => {
    const formattedActivities = activitiesAux.reduce((acc, activity, index) => {
      acc[index] = {
        id: activity?.id || null,
        activity: activity.activityInput,
        situation: activity.situationInput,
        env_impact_aspects_attributes: activity.env_impact_aspects_attributes,
        _destroy: activity?._destroy || false,
      };
      return acc;
    }, {});
    setActivities(formattedActivities);
  }, [activitiesAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpactAux };
    updatedImpact.env_impact_activities_attributes = activities;
    setEnvImpactAux(updatedImpact);
  }, [activities]);

  useEffect(() => {
    GetEnvironmentalAspects().then((data) => {
      setAspects(data);
    });
  }, []);

  useEffect(() => {
    if (!!envImpact.env_impact_activities_attributes[indexActivity]) {
      const formattedAspects = envImpact.env_impact_activities_attributes[
        indexActivity
      ].env_impact_aspects_attributes.reduce((acc, aspect, index) => {
        acc[index] = {
          id: aspect?.id || null,
          indexEnvAspect: index,
          descriptionInput: aspect.environmental_aspect_description,
          environmentalSelect: aspect.environmental_aspect_id,
          env_impact_evaluations_attributes:
            aspect.env_impact_evaluations_attributes,
          _destroy: false,
        };
        return acc;
      }, []);
      setEnvAspectsAux(formattedAspects);
      setEnvAspectsCounter(
        envImpact.env_impact_activities_attributes[indexActivity]
          .env_impact_aspects_attributes.length - 1
      );
    }
  }, [envImpact]);

  const addAspect = () => {
    const localCounter = envAspectsCounter + 1;
    const newAspect = {
      id: null,
      indexEnvAspect: localCounter,
      environmentalSelect: aspects[0].id,
      descriptionInput: "",
      env_impact_evaluations_attributes: {},
      _destroy: false,
    };
    setEnvAspectsCounter(envAspectsCounter + 1);
    setEnvAspectsAux([...envAspectsAux, newAspect]);
  };

  const deleteAspect = (e) => {
    const item = envAspectsAux.find((item) => item.indexEnvAspect === e);
    if (!!item.id) {
      const indexToUpdate = envAspectsAux.findIndex(
        (item) => item.indexEnvAspect === e
      );
      const newObj = { ...envAspectsAux[indexToUpdate], _destroy: true };
      setEnvAspectsAux((prevState) => {
        const newState = [...prevState];
        newState[indexToUpdate] = newObj;
        return newState;
      });
    } else {
      const updatedEnvAspects = envAspectsAux.filter(
        (proc) => proc.indexEnvAspect !== e
      );
      const reindexedEnvAspects = updatedEnvAspects.map((proc, index) => ({
        ...proc,
        indexEnvAspect: index,
      }));
      setEnvAspectsCounter(envAspectsCounter - 1);
      setEnvAspectsAux(reindexedEnvAspects);
    }
  };

  if (collapsed) {
    return (
      <div className={`${activity._destroy ? "d-none" : ""}`}>
        <div className="form-row ">
          <div className="col-md-12">
            <div className="d-flex form-group mb-3">
              <div
                className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer"
                onClick={() => setCollapsed(false)}
              >
                <div className="">
                  <span>Actividad #{indexActivity + 1}</span>
                </div>
                <div>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
              <div className="col-md-1 d-flex justify-content-center cursor-pointer">
                <a
                  className="btn btn-outline-danger"
                  onClick={() => deleteActivity(indexActivity)}
                >
                  <i className="fas fa-times text-danger"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${activity._destroy ? "d-none" : ""}`}>
        <div className={`form-row`}>
          <div className="col-md-12">
            <div className={`form-row mb-3`}>
              <div className="d-flex col-md-12 mb-3 p-0">
                <div
                  className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer"
                  onClick={() => setCollapsed(true)}
                >
                  <div className="">
                    <span>Actividad #{indexActivity + 1}</span>
                  </div>
                  <div>
                    <i className="fas fa-chevron-up"></i>
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center cursor-pointer">
                  <a
                    className="btn btn-outline-danger"
                    onClick={() => deleteActivity(indexActivity)}
                  >
                    <i className="fas fa-times text-danger"></i>
                  </a>
                </div>
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fas fa-file-alt form-icon-small"></i>
                  </div>
                </div>
                <textarea
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                  value={activity?.activityInput}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Situación: </label>
            </div>
            <div className="form-group form-check-inline flex-wrap">
              <div className="d-flex justify-content-center mr-4 ">
                <input
                  className="mr-2"
                  type="radio"
                  checked={activity?.situationInput === 0}
                  onChange={() => handleRadioChange(0)}
                />
                <label className="mb-0 mr-2">Normal</label>
              </div>
              <div className="d-flex justify-content-center mr-4 ">
                <input
                  className="mr-2"
                  type="radio"
                  checked={activity?.situationInput === 1}
                  onChange={() => handleRadioChange(1)}
                />
                <label className="mb-0 mr-2">Anormal</label>
              </div>
              <div className="d-flex justify-content-center mr-4 ">
                <input
                  className="mr-2"
                  type="radio"
                  checked={activity?.situationInput === 2}
                  onChange={() => handleRadioChange(2)}
                />
                <label className="mb-0 mr-2">Emergencia</label>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-3">
          {envAspectsAux.map((aspect) => (
            <Aspect
              key={aspect.indexEnvAspect}
              indexAspect={aspect.indexEnvAspect}
              aspect={aspect}
              deleteAspect={deleteAspect}
              aspects={aspects}
              indexActivity={indexActivity}
              envImpactAux={envImpactAux}
              envImpact={envImpact}
              setEnvImpactAux={setEnvImpactAux}
              envAspectsAux={envAspectsAux}
              setEnvAspectsAux={setEnvAspectsAux}
              envAspects={envAspects}
              setEnvAspects={setEnvAspects}
              activitiesAux={activitiesAux}
              setActivitiesAux={setActivitiesAux}
            />
          ))}
          <div className="card-body p-3">
            <button
              type="button"
              className="btn btn-info btn-sm mb-2"
              onClick={addAspect}
            >
              <i className="fas fa-plus mr-1"></i>
              Agregar
            </button>
            <span className="ml-2"> Agregar Aspecto</span>
          </div>
        </div>
      </div>
    );
  }
};

export default Activity;
