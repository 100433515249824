import React from 'react'
import useFetch from 'use-http'
import ListData from './ListData'
import Spinner from './Spinner'
const ProcedureList = (props) => {
  const { title, type } = props
  const options = {}
  const { loading, error, data = [] } = useFetch('/api/v1/metrics/type_procedures?type='+type, options, [])
  const handleOnClick = () => {
    //console.log(title)
  }
  return (
    <ul className='list-group'>
      {loading && <Spinner/>}
      {<ListData data = {data} onClick={handleOnClick}/>}
    </ul>
  )
}

export default ProcedureList
