import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import Resources from "./Resources";

const Impact = ({
  indexImpact,
  deleteImpact,
  impactsAux,
  setImpactsAux,
  impact,
  risks,
  impacts,
  setImpacts,
  envImpact,
  setEnvImpact,
  indexActivity,
  indexAspect,
  envAspectsAux,
  setEnvAspectsAux,
}) => {
  const [natureModal, setNatureModal] = useState(false);
  const [intensityModal, setIntensityModal] = useState(false);
  const [extensionModal, setExtensionModal] = useState(false);
  const [momentumModal, setMomentumModal] = useState(false);
  const [persistencyModal, setPersistencyModal] = useState(false);
  const [reversibilityModal, setReversibilityModal] = useState(false);
  const [sinergyModal, setSinergyModal] = useState(false);
  const [accumulateModal, setAccumulateModal] = useState(false);
  const [effectModal, setEffectModal] = useState(false);
  const [periodicityModal, setPeriodicityModal] = useState(false);
  const [recoveryModal, setRecoveryModal] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedImpacts = [...impactsAux];
    updatedImpacts[indexImpact] = {
      ...updatedImpacts[indexImpact],
      [name]: value,
    };
    setImpactsAux(updatedImpacts);
  };

  const handleRadioChange = (string, e) => {
    const { name, value } = e.target;
    const updatedImpacts = [...impactsAux];
    updatedImpacts[indexImpact] = {
      ...updatedImpacts[indexImpact],
      [name]: string,
    };
    setImpactsAux(updatedImpacts);
  };

  const handleRadioChangeSecond = (e, number) => {
    const { name, value } = e.target;
    const updatedImpacts = [...impactsAux];
    updatedImpacts[indexImpact] = {
      ...updatedImpacts[indexImpact],
      [name]: number,
    };
    setImpactsAux(updatedImpacts);
  };

  const handleSelect = (idImpact, e) => {
    const { value } = e.target;
    setImpactsAux((prevImpactsAux) =>
      prevImpactsAux.map((item) =>
        item.indexImpact === idImpact ? { ...item, risk_id: value } : item
      )
    );
  };

  useEffect(() => {
    const formattedImpacts = impactsAux.reduce((acc, impact, index) => {
      acc[index] = {
        description: impact.description,
        control: impact.control,
        character: impact.character,
        intensity: impact.intensity,
        impact_extension: impact.impact_extension,
        moment: impact.moment,
        persistence: impact.persistence,
        reversibility: impact.reversibility,
        synergy: impact.synergy,
        accumulation: impact.accumulation,
        effect: impact.effect,
        periodicity: impact.periodicity,
        recoverability: impact.recoverability,
        risk_id: impact.risk_id,
        risk_description: impact.risk_description,
        associated_opportunity_description:
          impact.associated_opportunity_description,
        observations: impact.observations,
        significant_impact: impact.significant_impact,
        resources_attributes: impact.resources,
        _destroy: false,
      };
      return acc;
    }, {});
    setImpacts(formattedImpacts);
  }, [impactsAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpact };
    const updatedAspects = [ ...envAspectsAux ];
    updatedImpact.environmental_impact.env_impact_activities_attributes[
      indexActivity
    ].env_impact_aspects_attributes[
      indexAspect
    ].env_impact_evaluations_attributes = impacts;
    updatedAspects[indexAspect].evaluations = impacts;
    setEnvImpact(updatedImpact);
    setEnvAspectsAux(updatedAspects);
  }, [impacts]);

  if (collapsed) {
    return (
      <>
        <div className="form-row px-3 mt-3">
          <div className="col-md-12">
            <div className="d-flex form-group mb-0">
              <div
                className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer"
                onClick={() => setCollapsed(false)}
              >
                <div className="">
                  <span>Impacto Ambiental #{indexImpact + 1}</span>
                </div>
                <div>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
              <div className="col-md-1 d-flex justify-content-center">
                <a
                  className="btn btn-outline-danger"
                  onClick={() => deleteImpact(indexImpact)}
                >
                  <i className="fas fa-times text-danger"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="mt-3">
          <div className="col-md-12">
            <div className="d-flex col-md-12 p-0">
              <div
                className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer "
                onClick={() => setCollapsed(true)}
              >
                <div className="">
                  <span>Impacto Ambiental #{indexImpact + 1}</span>
                </div>
                <div>
                  <i className="fas fa-chevron-up"></i>
                </div>
              </div>
              <div className="col-md-1 d-flex justify-content-center">
                <a
                  className="btn btn-outline-danger"
                  onClick={() => deleteImpact(indexImpact)}
                >
                  <i className="fas fa-times text-danger"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="form-row">
              <Resources
                envImpact={envImpact}
                setEnvImpact={setEnvImpact}
                indexActivity={indexActivity}
                indexAspect={indexAspect}
                indexImpact={indexImpact}
                impactsAux={impactsAux}
                setImpactsAux={setImpactsAux}
              />
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <span className="mb-2">Impacto generado</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-file-alt form-icon-small"></i>
                      </div>
                    </div>
                    <textarea
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={impact?.description}
                      name="description"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="card-header py-0 px-2 bg-white">
            <div className="card-title">
              <span> Evaluación del impacto</span>
            </div>
          </div>
          <div className="card-body">
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <span className="mb-2">Control existente</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-file-alt form-icon-small"></i>
                      </div>
                    </div>
                    <textarea
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={impact?.control}
                      name="control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-10 mb-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setNatureModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Naturaleza - signo </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline flex-wrap">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.character === "t"}
                        onChange={(e) => handleRadioChange("t", e)}
                        name="character"
                      />
                      <label className="mb-0 mr-2">Positivo</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.character === "f"}
                        onChange={(e) => handleRadioChange("f", e)}
                        name="character"
                      />
                      <label className="mb-0 mr-2">Negativo</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setIntensityModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Intensidad </label>
                  <small className="d-block">
                    Califica la dimensión o tamaño del cambio ambiental
                    producido por una actividad o proceso constructivo u
                    operativo
                  </small>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline flex-wrap">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.intensity === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="intensity"
                      />
                      <label className="mb-0 mr-2">Baja</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.intensity === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="intensity"
                      />
                      <label className="mb-0 mr-2">Media</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.intensity === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="intensity"
                      />
                      <label className="mb-0 mr-2">Alta</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.intensity === 8}
                        onChange={(e) => handleRadioChangeSecond(e, 8)}
                        name="intensity"
                      />
                      <label className="mb-0 mr-2">Muy Alta</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.intensity === 12}
                        onChange={(e) => handleRadioChangeSecond(e, 12)}
                        name="intensity"
                      />
                      <label className="mb-0 mr-2">Total</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setExtensionModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Extensión </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline flex-wrap">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.impact_extension === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="impact_extension"
                      />
                      <label className="mb-0 mr-2">Puntual</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.impact_extension === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="impact_extension"
                      />
                      <label className="mb-0 mr-2">Local</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.impact_extension === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="impact_extension"
                      />
                      <label className="mb-0 mr-2">Extenso</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.impact_extension === 8}
                        onChange={(e) => handleRadioChangeSecond(e, 8)}
                        name="impact_extension"
                      />
                      <label className="mb-0 mr-2">Total</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.impact_extension === 12}
                        onChange={(e) => handleRadioChangeSecond(e, 12)}
                        name="impact_extension"
                      />
                      <label className="mb-0 mr-2">Crítico</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setMomentumModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Momento </label>
                  <small className="d-block">
                    Es el tiempo que trascurre entre el inicio de la Actividad y
                    la aparición del impacto sobre el elemento del medio
                    considerado.
                  </small>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.moment === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="moment"
                      />
                      <label className="mb-0 mr-2">Largo plazo</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.moment === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="moment"
                      />
                      <label className="mb-0 mr-2">Mediano plazo</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.moment === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="moment"
                      />
                      <label className="mb-0 mr-2">Corto plazo</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.moment === 8}
                        onChange={(e) => handleRadioChangeSecond(e, 8)}
                        name="moment"
                      />
                      <label className="mb-0 mr-2">Inmediato</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.moment === 12}
                        onChange={(e) => handleRadioChangeSecond(e, 12)}
                        name="moment"
                      />
                      <label className="mb-0 mr-2">Crítico</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setPersistencyModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Persistencia </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.persistence === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="persistence"
                      />
                      <label className="mb-0 mr-2">Fugaz</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.persistence === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="persistence"
                      />
                      <label className="mb-0 mr-2">Temporal</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.persistence === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="persistence"
                      />
                      <label className="mb-0 mr-2">Permanente</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setReversibilityModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Reversibilidad </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.reversibility === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="reversibility"
                      />
                      <label className="mb-0 mr-2">Corto plazo</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.reversibility === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="reversibility"
                      />
                      <label className="mb-0 mr-2">Mediano plazo</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.reversibility === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="reversibility"
                      />
                      <label className="mb-0 mr-2">Irreversible</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setSinergyModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Sinergia </label>
                  <small className="d-block">
                    Es el tiempo que trascurre entre el inicio de la Actividad y
                    la aparición del impacto sobre el elemento del medio
                    considerado.
                  </small>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.synergy === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="synergy"
                      />
                      <label className="mb-0 mr-2">Sin sinergismo</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.synergy === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="synergy"
                      />
                      <label className="mb-0 mr-2">Sinérgico</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.synergy === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="synergy"
                      />
                      <label className="mb-0 mr-2">Muy sinérgico</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setAccumulateModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Acumulación </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.accumulation === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="accumulation"
                      />
                      <label className="mb-0 mr-2">Simple</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.accumulation === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="accumulation"
                      />
                      <label className="mb-0 mr-2">Acumulativo</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setEffectModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Efecto </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.effect === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="effect"
                      />
                      <label className="mb-0 mr-2">Indirecto(secundario)</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.effect === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="effect"
                      />
                      <label className="mb-0 mr-2">Directo</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setPeriodicityModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Periodicidad </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.periodicity === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="periodicity"
                      />
                      <label className="mb-0 mr-2">
                        Irregular o periódico discontínuo
                      </label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.periodicity === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="periodicity"
                      />
                      <label className="mb-0 mr-2">Periódico</label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.periodicity === 4}
                        onChange={(e) => handleRadioChangeSecond(e, 4)}
                        name="periodicity"
                      />
                      <label className="mb-0 mr-2">Contínuo</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row border-bottom">
              <div className="col-12 my-3">
                <div className="form-group">
                  <button
                    className="btn btn-info btn-sm rounded-circle mr-3"
                    onClick={() => setRecoveryModal(true)}
                  >
                    <i className="far fa-question-circle"></i>
                  </button>
                  <label> Recuperabilidad </label>
                </div>
                <div className="form-group mb-2">
                  <div className="form-group form-check-inline">
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.recoverability === 1}
                        onChange={(e) => handleRadioChangeSecond(e, 1)}
                        name="recoverability"
                      />
                      <label className="mb-0 mr-2">
                        Recuperación de manera inmediata
                      </label>
                    </div>
                    <div className="d-flex justify-content-center mr-4">
                      <input
                        className="mr-2"
                        type="radio"
                        checked={impact?.recoverability === 2}
                        onChange={(e) => handleRadioChangeSecond(e, 2)}
                        name="recoverability"
                      />
                      <label className="mb-0 mr-2">
                        Recuperable en mediano plazo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-3 mt-3">
          <div className="card-header bg-white">
            <div className="card-title">
              <span> Riesgos y oportunidades </span>
            </div>
          </div>
          <div className="card-body">
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <span className="mb-2">Riesgo asociado</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-exclamation-triangle form-icon-small"></i>
                      </div>
                    </div>
                    <select
                      className="form-control custom-select"
                      onChange={(e) => handleSelect(indexImpact, e)}
                      value={impact.risk_id}
                    >
                      {risks.map((risk) => (
                        <option key={risk.id} value={risk.id}>
                          {risk.characteristic}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <span className="mb-2">Descripción del riesgo asociado</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-exclamation-triangle form-icon-small"></i>
                      </div>
                    </div>
                    <textarea
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={impact?.risk_description}
                      name="risk_description"
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <span className="mb-2">Descripción de la oportunidad</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-file-alt form-icon-small"></i>
                      </div>
                    </div>
                    <textarea
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={impact?.associated_opportunity_description}
                      name="associated_opportunity_description"
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <span className="mb-2">Observaciones</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-file-alt form-icon-small"></i>
                      </div>
                    </div>
                    <textarea
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={impact?.observations}
                      name="observations"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-3 mt-3">
          <div className="card-header bg-white">
            <div className="card-title">
              <span> Indicador de significancia de Impactos ambientales </span>
            </div>
          </div>
          <div className="card-body">
            <div className="col-md-12">
              <div className="form-group">
                <label>Situación: </label>
              </div>
              <div className="form-group form-check-inline">
                <div className="d-flex justify-content-center mr-4">
                  <input
                    className="mr-2"
                    type="radio"
                    checked={impact?.significant_impact === "t"}
                    onChange={(e) => handleRadioChange("t", e)}
                    name="significant_impact"
                  />
                  <label className="mb-0 mr-2">Impacto significativo</label>
                </div>
                <div className="d-flex justify-content-center mr-4">
                  <input
                    className="mr-2"
                    type="radio"
                    checked={impact?.significant_impact === "f"}
                    onChange={(e) => handleRadioChange("f", e)}
                    name="significant_impact"
                  />
                  <label className="mb-0 mr-2">Impacto no significativo</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {natureModal && <Modal modalFlag={0} setFunction={setNatureModal} />}
        {intensityModal && (
          <Modal modalFlag={1} setFunction={setIntensityModal} />
        )}
        {extensionModal && (
          <Modal modalFlag={2} setFunction={setExtensionModal} />
        )}
        {momentumModal && (
          <Modal modalFlag={3} setFunction={setMomentumModal} />
        )}
        {persistencyModal && (
          <Modal modalFlag={4} setFunction={setPersistencyModal} />
        )}
        {reversibilityModal && (
          <Modal modalFlag={5} setFunction={setReversibilityModal} />
        )}
        {sinergyModal && <Modal modalFlag={6} setFunction={setSinergyModal} />}
        {accumulateModal && (
          <Modal modalFlag={7} setFunction={setAccumulateModal} />
        )}
        {effectModal && <Modal modalFlag={8} setFunction={setEffectModal} />}
        {periodicityModal && (
          <Modal modalFlag={9} setFunction={setPeriodicityModal} />
        )}
        {recoveryModal && (
          <Modal modalFlag={10} setFunction={setRecoveryModal} />
        )}
      </>
    );
  }
};

export default Impact;
