import React, { useState } from 'react'
import Tooltip from '../../../Tooltip'

const TableRow = ({environmentalImpact, canSee, canEdit}) => {

  const activityNames = (activities) => {
    let text = activities.map(object => object.activity).join(', ');
    return text.slice(0, 120);
  }

  const procedureNames = (procedures) => {
    let text = procedures.map(object => object.procedure).join(', ');
    return text.slice(0, 120);
  }

  return (
    <div className="container-fluid ml-0 mr-0 mb-0">
    <div className="row border-top border-bottom">
      <div className="col-2 px-1 py-2">
        <div className="d-flex justify-content-between align-middle">
          <div className="d-flex">
            <Tooltip text={"Plan de acción"}>
              {canSee && (
                <a href={`/environmental_matrices/${environmentalImpact.environmental_matrix_id}/environmental_impacts/${environmentalImpact.id}/environmental_impact_actions`}
                  className="btn btn-primary btn-sm mr-1">
                  Plan de acción
                </a>
              )}
            </Tooltip>
            <Tooltip text={"Editar"}>
              {canEdit && (
                <a href={`/environmental_matrices/${environmentalImpact.environmental_matrix_id}/environmental_impacts/${environmentalImpact.id}/edit`}
                  className="btn btn-outline-success btn-sm mr-1">
                  <i className="far fa-edit"></i>
                </a>
              )}
            </Tooltip>
            <Tooltip text={"Borrar"}>
              {canEdit && (
                <a href={`/environmental_matrices/${environmentalImpact.environmental_matrix_id}/environmental_impacts/${environmentalImpact.id}`}
                  className="btn btn-sm btn-outline-danger mr-1"
                  data-method="delete">
                  <i className="fas fa-trash"></i>
                </a>
              )}
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="col-5 text-center py-2 px-0 d-none d-lg-block">
        {activityNames(environmentalImpact.env_impact_activities)}
      </div>
      <div className="col-5 text-center py-2 px-0 d-xs-none d-md-block">
        {procedureNames(environmentalImpact.multi_procedures)}
      </div>
    </div>
  </div>
  )
}

export default TableRow
