export const GetProcedures = async () => {
  try {
    const url = '/api/v1/indicators/procedures'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
};

export const GetIndicators = async(procedureId) => {
  try {
    const url = '/api/v1/indicators?procedure_id=' + procedureId
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const GetPortal = async() => {
  try {
    const url = '/api/v1/indicators/portal'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const GetPeriodicities = async() => {
  try {
    const url = '/api/v2/periodicities'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const GetResponsibles = async() => {
  try {
    const url = '/api/v2/employees'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const GetAvailableLines = async() => {
  try {
    const url = '/api/v1/indicators/available_lines'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const GetPrograms = async(availableLineId) => {
  try {
    const url = '/api/v1/indicators/filter_programs?select_id=' + availableLineId
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const sendFilters = async(filterParams) => {
  try {
    const baseUrl = "/api/v1/indicators";
    const params = new URLSearchParams();
    params.append("filters[term]", filterParams.input);
    params.append("filters[strategic_line_id]", filterParams.line);
    params.append("filters[program]", filterParams.program);
    params.append("filters[type_of_indicator]", filterParams.type);
    params.append("filters[periodicity]", filterParams.periodicity);
    params.append("filters[name]", filterParams.name);
    params.append("filters[objective]", filterParams.objective);
    params.append("filters[instruccions]", filterParams.instruccions);
    params.append("filters[source]", filterParams.source);
    params.append("filters[formula]", filterParams.formula);
    params.append("filters[responsible_id]", filterParams.responsible_id);
    params.append("filters[metrics_responsible_id]", filterParams.metrics_responsible_id);
    const url = `${baseUrl}?${params}`;
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const GetEstrategy = async() => {
  try {
    const url = '/api/v1/indicators/estrategy'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}
