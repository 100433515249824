import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    //var ratified_true = document.getElementById("U");
    var justify = document.getElementById("justify");
    var employee_2 = document.getElementById("employe_resp");
    var area = document.getElementById("area");
    var service_type =document.getElementById("service_type");
    var type_received = document.getElementById("type_received");
    var type_of_request = document.getElementById("type_of_request");
    var management_system = document.getElementById("management_system");
    var business = document.getElementById("business");
    area.style.display = ''
    service_type.style.display = ''
    justify.style.display = 'none'
    employee_2.style.display = ''
    type_received.style.display = ''
    type_of_request.style.display = ''
    if (!(management_system === null) && !(business === null)){
      management_system.style.display = ''
      business.style.display = ''
    }
    this.onChange()
  }

  onChange(e) {
    var ratified_true = document.getElementById("pqrsf_ratified_true");
    var ratified_false = document.getElementById("pqrsf_ratified_false");
    var justify = document.getElementById("justify");
    var area = document.getElementById("area");
    var service_type =document.getElementById("service_type");
    var employee_2 = document.getElementById("employe_resp");
    var management_system = document.getElementById("management_system");
    var business = document.getElementById("business");    
    ratified_false.addEventListener("change", function(){
      if (ratified_false.value == 'false'){
        justify.style.display = '';
        employee_2.style.display = 'none';
        area.style.display = 'none';
        service_type.style.display = 'none';
        type_received.style.display = 'none';
        type_of_request.style.display = 'none';        
        if (!(management_system === null) && !(business === null)){
          management_system.style.display = ''
          business.style.display = ''
        }
      }
    })

    ratified_true.addEventListener("change", function(){
      if (ratified_true.value == 'true'){
        justify.style.display = 'none';
        employee_2.style.display = '';
        area.style.display = '';
        service_type.style.display = '';
        type_received.style.display = '';
        type_of_request.style.display = '';
        if (!(management_system === null) && !(business === null)){
          management_system.style.display = ''
          business.style.display = ''
        }
      }
    })


  }


}
