import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    console.log('conect')
    const buttonChange = document.getElementById('button_change')
    buttonChange.addEventListener('click', function() {
      const time = 5;
      // Paso 3: Definir función de manejo de eventos
      console.log('Se ha hecho clic en el botón');
      setTimeout(function() {
        buttonChange.disabled = true;
      }, time);
      // submitButton.disabled = true
    })
  }
}
