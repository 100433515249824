import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PromedioComponent = ({ evaluated, evaluationId }) => {
  const [promedio, setPromedio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPromedio = async () => {
      try {
        const url = "/api/v2/average_evaluations/promedio_show";
        const response = await axios.get(url, {
          params: {
            evaluated: evaluated,
            evaluation_id: evaluationId,
          },
        });
        setPromedio(response.data.average);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPromedio();
    console.log(fetchPromedio());
  }, [evaluated, evaluationId]);

  if (loading) return <div>Calculando...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h6>{promedio}</h6>
    </div>
  );
};

export default PromedioComponent
