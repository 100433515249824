import { Controller }  from 'stimulus'


export default class extends Controller {

  static targets = ['buttonsubmit', 'answer', 'answerA', 'answerB', 'input', 'check']
  onChange ()
  {
    $(this.buttonsubmitTarget).prop('className', 'btn disabled')
  }
}
