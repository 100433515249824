import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['serial', 'asset', 'dropdown']
  connect () {
    this.onChange();
  }

  onChange (e) {
    if (e) { e.preventDefault() }
    if (this.hasDropdownTarget && this.hasSerialTarget) {
      const serial = $(this.dropdownTarget).find(':selected').data('serial')
      const fixedAsset = $(this.dropdownTarget).find(':selected').data('asset')
      $(this.serialTarget).text( serial )
      $(this.assetTarget).text( fixedAsset )
    }
  }

  triggerSelectChange(e){
    // Encuentra el select usando el atributo name
    const areaSelected = document.querySelector('select[name="corrective_maintenance[area_id]"]');
    
    // Verifica si el select fue encontrado antes de intentar despachar el evento
    if (areaSelected) {
      // Despacha el evento 'change' en el select
      areaSelected.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }
}
