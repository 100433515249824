import React, {useState, useEffect} from 'react'
import Header from './Header'
import Modal from './Modal'
import Procedures from './Procedures'
import { GetProcedures, GetPeriodicities, GetAvailableLines, GetPrograms, sendFilters, GetResponsibles } from './Requests'

const IndicatorsList = (props) => {
  const permissions = props.permissions
  const user = props.user
  const portal = props.portal
  const publicFlag = props.portal.public_entity
  const [responsibles, setResponsibles] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [availableLines, setAvailableLines] = useState([]);
  const [linesFlag, setLinesFlag] = useState(false);
  const [programTerm, setProgramTerm] = useState('');
  const [programs, setPrograms] = useState([]);
  const [modalSearch, setModalSearch] = useState(false);
  const [buttonFlag, setButtonFlag] = useState(false);
  const [filterParams, setFilterParams] = useState({
    input: '',
    program: '',
    line: '',
    type: '',
    periodicity: '',
    name: '',
    objective: '',
    instruccions: '',
    source: '',
    formula: '',
    responsible_id: '',
    metrics_responsible_id: ''
  });
  const [filterFlag, setFilterFlag] = useState(false);
  const [indicatorsFiltered, setIndicatorsFiltered] = useState([]);

  useEffect(() => {
    GetProcedures()
      .then((data) => {
        data.forEach(procedure => { procedure.show = false, procedure.indicators_requested = false })
        setProcedures(data);
      })
    GetPeriodicities()
      .then((data) => {
        setPeriodicities(data);
      })
    GetAvailableLines()
      .then((data) => {
        setAvailableLines(data);
      })
    GetResponsibles()
      .then((data) => {
        setResponsibles(data)
      })
  }, []);

  useEffect(() => {
    if (availableLines.length > 0) {
      setLinesFlag(true);
    } else {
      setLinesFlag(false);
    }
  }, [availableLines])

  useEffect(() => {
    GetPrograms(programTerm)
      .then((data) => {
        setPrograms(data);
      })
  }, [programTerm])

  useEffect(() => {
    if (filterFlag === true) {
      sendFilters(filterParams)
      .then((data) => {
        setIndicatorsFiltered(data);
        setModalSearch(false);
        setFilterParams({
          input: '',
          program: '',
          line: '',
          type: '',
          periodicity: '',
          name: '',
          objective: '',
          instruccions: '',
          source: '',
          formula: '',
          responsible_id: '',
          metrics_responsible_id: ''
        });
        setFilterFlag(false);
      })
    }
    if (indicatorsFiltered.length > 0) {
      const updatedProcedures = [...procedures];
      updatedProcedures.forEach((procedure) => {
        const matchingIndicators = indicatorsFiltered.filter((indicator) => procedure.id === indicator.procedure_id);
        if (matchingIndicators.length > 0) {
          procedure.show = false;
          procedure.indicators = matchingIndicators;
          procedure.indicators_requested = true;
        }
        procedure.count = procedure.indicators?.length || 0;
      });

      setProcedures(updatedProcedures);
      setButtonFlag(true);
    }
  }, [filterFlag])

  return(
    <React.Fragment>
      <Header
        setModalSearch={setModalSearch}
        buttonFlag={buttonFlag}
      />
      {procedures.length === 0 && (
        <React.Fragment>
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <div>
              <div className="d-flex justify-content-center align-items-center">
                {procedures.length === 0 && <h4>Cargando</h4>}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {(procedures.length > 0 === true) && <Procedures list = {procedures}
                                                       permissions = {permissions}
                                                       user = {user}
                                                       portal = {portal}
                                                       indicatorsFiltered={indicatorsFiltered}
                                                       filterFlag={filterFlag} />}
      { modalSearch && (
        <Modal
          setModalSearch={setModalSearch}
          publicFlag={publicFlag}
          periodicities={periodicities}
          linesFlag={linesFlag}
          availableLines={availableLines}
          setProgramTerm={setProgramTerm}
          programs={programs}
          filterParams={filterParams}
          setFilterFlag={setFilterFlag}
          setFilterParams={setFilterParams}
          responsibles={responsibles}
        />)
      }
    </React.Fragment>
  )
}

export default IndicatorsList
