import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    this.onChange()
  }

  onChange(e) {
    if (e) e.preventDefault();
    const url =  this.data.get('url')
    const elementId = this.data.get('target')
    console.log('url' + url)
    console.log('elementId' + elementId)
    if (elementId !== null)
    {
      $.get(url+'?commitee_id='+this.element.value).then((content) => {
        document.getElementById(elementId).innerHTML = content
      })
    }
  }
}
