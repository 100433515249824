import { Controller } from 'stimulus'

export default class extends Controller {

  connect () {   
    this.verify()
  }

  verify(e) {
    const analysis = document.querySelectorAll('.grupoAnalysis');
    const grupoBrainstorming = document.querySelectorAll('.grupoBrainstorming');
    var expresionAnalisis = new RegExp("\\b" + '5|cinco|Cinco' + "\\b", "i");
    var expresionbrain = new RegExp("\\b" + 'idea|ideas|Ideas' + "\\b", "i");

    if (!expresionAnalisis.test($("#select_cause_id option:selected").text().toLowerCase())){
      analysis.forEach(element => {
        element.style.display = 'none';
      })
    } else {
      analysis.forEach(element => {
        element.style.display = '';
      });
    }

    if (!expresionbrain.test($("#select_cause_id option:selected").text().toLowerCase())){
      grupoBrainstorming.forEach(element => {
        element.style.display = 'none';
      })
    }else {
      grupoBrainstorming.forEach(element => {
        element.style.display = '';
      });
    }
  }
}
