import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    this.onChange()
  }

  onChange(e) {
    if (e) e.preventDefault();
    const url =  this.data.get('url')
    const elementId = this.data.get('target')
    const selected = this.data.get('selected')
    $.get(url+'?area_id='+this.element.value+'&selected_id='+selected).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
  }
}
