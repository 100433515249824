import { Controller } from 'stimulus';
import 'select2';
import 'select2/dist/js/i18n/es';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['button', 'buttonone', 'buttontwo', 'buttonthree', 'buttonNA',
    'span', 'chapter', 'subchapter', 'spanchapter', 'action', 'submitButton'];

  connect() {
    var chapter = document.getElementsByClassName("chapter");
    $(this.actionTarget).prop('className', 'btn btn-outline-info btn-sm mr-1 disabled');
    // var button_submit = document.getElementById('submit-button');
    // $(button_submit).prop('className', 'btn btn-primary disabled');
    for (var x = 0; x < chapter.length; x++) {
      if (chapter[x].value == '-1.0') {
        document.getElementById("spanchapter" + chapter[x].id).textContent = 'NA';
      } else {
        document.getElementById("spanchapter" + chapter[x].id).textContent = chapter[x].value + "%";
      }
    }
    this.checkValues();

  }

  checkValues() {
    var selected = this.buttonTarget.value;
    var button_submit = document.getElementById('submit-button');
    if (selected == 100) {
      $(this.buttononeTarget).prop('className', 'btn btn-outline-success btn-sm mr-1 assigne');
    } else if (selected == 50) {
      $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1 assigne');
      $(this.actionTarget).prop('className', 'btn btn-info btn-sm mr-1');
    } else if (selected == 0) {
      $(this.buttonthreeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1 assigne');
      $(this.actionTarget).prop('className', 'btn btn-info btn-sm mr-1');
    } else {
      $(button_submit).prop('className', 'btn btn-primary disabled');
    }
  }



  save() {
    const elem = document.getElementById('inspectionForm'); // or $('#myform')[0] with jQuery
    //Rails.fire(elem, 'submit');
  }

  saveForm() {
    const elem = document.getElementById('inspectionForm'); // or $('#myform')[0] with jQuery
    //console.log(elem);
    Rails.fire(elem, 'submit');
  }

  resetClass() {
    $(this.buttononeTarget).prop('className', 'btn btn-success btn-sm mr-1');
    $(this.buttontwoTarget).prop('className', 'btn btn-warning btn-sm mr-1');
    $(this.buttonthreeTarget).prop('className', 'btn btn-danger btn-sm mr-1');
  }

  onAssigneSave(e) {
    this.saveForm();
  }

  onAssigne1(e) {
    if (e) e.preventDefault();
    this.resetClass();
    $(this.buttononeTarget).prop('className', 'btn btn-outline-success btn-sm mr-1 assigne');
    this.buttonTarget.value = '100';
    this.onChange(this.buttonTarget.id);
    this.save();
    var button_action = document.getElementById("action_" + this.buttononeTarget.id);
    $(button_action).prop('className', 'btn btn-outline-info btn-sm mr-1 disabled');
    this.Finished()
  }

  onAssigne2(e) {
    if (e) e.preventDefault();
    this.resetClass();
    $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1 assigne');
    this.buttonTarget.value = '50';
    this.onChange(this.buttonTarget.id);
    this.save();
    var button_action = document.getElementById("action_" + this.buttontwoTarget.id);
    $(button_action).prop('className', "btn btn-info btn-sm mr-1");
    this.Finished()
  }

  onAssigne3(e) {
    if (e) e.preventDefault();
    this.resetClass();
    $(this.buttonthreeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1 assigne');
    this.buttonTarget.value = '0';
    this.onChange(this.buttonTarget.id);
    this.save();
    var button_action = document.getElementById("action_" + this.buttonthreeTarget.id);
    $(button_action).prop('className', "btn btn-info btn-sm mr-1");
    this.Finished()
  }

  onAssigneAction(e) {
    this.saveForm();
  }

  onAssigne5(e) {
    if (e) e.preventDefault();
    this.resetClass();
    this.buttonTarget.value = '-1';
    this.onChange(this.buttonTarget.id);
    this.save();
    var button_action = document.getElementById("action_" + this.buttonNATarget.id);
    $(button_action).prop('className', 'btn btn-outline-info btn-sm mr-1 disabled');
    var button_submit = document.getElementById('submit-button');
    $(button_submit).prop('className', 'btn btn-primary disabled');
  }

  onChange(id) {
    var number = document.getElementsByClassName(id);
    var sum = 0;
    var nils = 0;
    for (var x = 0; x < number.length; x++) {
      var value = number[x].value;
      if (value > -1) {
        sum = Number(sum) + Number(value);
      } else {
        nils = Number(nils) + 1;
      }
    }
    var prom = Number(sum) / (Number(number.length) - nils);
    var chapter = document.getElementsByClassName("chapter");
    for (var x = 0; x < chapter.length; x++) {
      if ("question" + chapter[x].id == id) {
        document.getElementById("spanchapter" + chapter[x].id).textContent = prom.toFixed(2) + "%";
        chapter[x].value = prom.toFixed(2);
      }
    }
  }

  Finished()
  {
    var elementosOutline = document.querySelectorAll('.assigne').length;
    var allelementosOutline = document.querySelectorAll('.nrow').length;
  
    if (elementosOutline == allelementosOutline)
    {
      var button_submit = document.getElementById('submit-button');
      $(button_submit).prop('className', 'btn btn-primary ');
    }else{
      var button_submit = document.getElementById('submit-button');
      $(button_submit).prop('className', 'btn btn-primary disabled');
    }
  }
}
