import { Controller } from "stimulus";

document.onkeydown = function (e) {
  var bg = document.getElementById("divimage");
  if (bg) {
    e = e || window.event; // Get event

    var code = e.which || e.keyCode; // Get key code
    switch (code) {
      case 17:
        return false;
      case 44:
        return false;
      case 83:
        return false;
      case 80:
        return false;
      case 88:
        return false;
    }
  }
};

function copyToClipboard() {
  var aux = document.createElement("input");
  aux.setAttribute(
    "value",
    "no esta permitido realizar impresión de pantalla de esta página."
  );
  document.body.appendChild(aux);
  aux.select();
  document.execCommand("copy");
  document.body.removeChild(aux);
}

$(window).keyup(function (e) {
  if (e.keyCode == 44) {
    copyToClipboard();
  }
});

$(document).ready(function () {
  $("#divimage").on("mouseenter", function () {
    var bg = document.getElementById("divimage");
    if (bg) {
      $("#documentFrame").show();
    }
  });
  $("#documentFrame").on("mouseenter", function () {
    var bg = document.getElementById("divimage");
    if (bg) {
      $("#documentFrame").show();
    }
  });
  $("#divimage").click(function () {
    var bg = document.getElementById("divimage");
    if (bg) {
      $("#documentFrame").show();
    }
  });
  $("#documentFrame").click(function () {
    var bg = document.getElementById("divimage");
    if (bg) {
      $("#documentFrame").show();
    }
  });
  $(window).keydown(function (e) {
    if ((e.keyCode == 70 && (e.ctrlKey || e.metaKey)) || e.keyCode == 191) {
      var bg = document.getElementById("divimage");
      if (bg) {
        $("#documentFrame").show();
      }
    }
  });
  $(window).blur(function () {
    var bg = document.getElementById("divimage");
    if (bg) {
      $("#documentFrame").hide();
    }
  });
  $(window).on("mouseenter", function () {
    var bg = document.getElementById("divimage");
    if (bg) {
      $("#documentFrame").show();
    }
  });
});

export default class extends Controller {
  static targets = ["divFrame"];

  connect() {
    if (
      document.getElementById("download_document") !== null &&
      document.getElementById("download_document") !== null
    ) {
      var data = document.getElementById("bandera");
      if (data.value == "true") {
        var miBoton = document.getElementById("download_document");
        var url = miBoton.getAttribute("href");
        window.location.href = url;
        data.value = "false";
      }
    }

    const frame = document.getElementById("documentFrame");

    if (!frame) {
      return;
    }

    var id = $(this.divFrameTarget).data("id");

    if (id) {
      frame.setAttribute("src", `/docs-viewer?id=${id}`);
    } else {
      var url = $(this.divFrameTarget).data("url");
      var urlEncoded = encodeURIComponent(url);
      frame.setAttribute(
        "src",
        "https://docs.google.com/viewer?url=" + urlEncoded + "&embedded=true"
      );

      const timerId = setTimeout(() => {
        var document_id = document.getElementById("document_id").value;
        this.onReload(document_id);
      }, 25000);

      $("#documentFrame").on("load", function () {
        clearTimeout(timerId);
      });
    }
  }

  onReload(document_id) {
    $.get("/documents/field_document", { document_id: document_id })
      .then((content) => {
        console.log("Contenido recibido:", content);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error al obtener el documento:", error);
      });
  }
}
