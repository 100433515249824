import React, { useState, useEffect } from "react";
import Header from "./Header";
import Procedures from "./FormEdit/Procedures";
import Activity from "./FormEdit/Activity";

const FormEdit = (props) => {
  const [matrixId] = useState(props.environmental_matrix.id);
  const [envImpact, setEnvImpact] = useState(props.environmental_impact);
  const [envImpactAux, setEnvImpactAux] = useState(props.environmental_impact);
  const [activitiesAux, setActivitiesAux] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activitiesCounter, setActivitiesCounter] = useState(0);

  useEffect(() => {
    const formattedActivitiesAux =
      envImpact.env_impact_activities_attributes.reduce(
        (acc, activity, index) => {
          acc[index] = {
            id: activity?.id || null,
            indexActivity: index,
            activityInput: activity.activity,
            situationInput: activity.situation,
            env_impact_aspects_attributes:
              activity.env_impact_aspects_attributes,
            _destroy: false,
          };
          return acc;
        },
        []
      );
    setActivitiesAux(formattedActivitiesAux);
  }, [envImpact]);

  useEffect(() => {
    const formattedActivities = envImpact.env_impact_activities_attributes;
    setActivities(formattedActivities);
    setActivitiesCounter(envImpact.env_impact_activities_attributes.length - 1);
  }, [envImpact]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let envImpactFinal = { environmental_impact: { ...envImpactAux } };
    const url = `/api/v1/environmental_impacts/${envImpact.id}`;
    const method = "PATCH";
    fetch(url, {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(envImpactFinal),
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {
        window.location.href = `/environmental_matrices/${matrixId}/environmental_impacts`;
        // setTimeout(() => {
        //   window.location.reload()
        // }, 500);
      })
      .catch((error) => console.error(error));
  };

  const addActivity = () => {
    const localCounter = activitiesCounter + 1;
    const newActivity = {
      id: null,
      indexActivity: localCounter,
      activityInput: "",
      situationInput: null,
      env_impact_aspects_attributes: {},
      _destroy: false,
    };
    setActivitiesCounter(activitiesCounter + 1);
    setActivitiesAux([...activitiesAux, newActivity]);
  };

  const deleteActivity = (e) => {
    const item = activitiesAux.find((item) => item.indexActivity === e);
    if (!!item.id) {
      const indexToUpdate = activitiesAux.findIndex(
        (item) => item.indexActivity === e
      );
      const newObj = { ...activitiesAux[indexToUpdate], _destroy: true };
      setActivitiesAux((prevState) => {
        const newState = [...prevState];
        newState[indexToUpdate] = newObj;
        return newState;
      });
    } else {
      const updatedActivities = activitiesAux.filter(
        (proc) => proc.indexActivity !== e
      );
      const reindexedActivities = updatedActivities.map((proc, index) => ({
        ...proc,
        indexActivity: index,
      }));
      setActivitiesCounter(activitiesCounter - 1);
      setActivitiesAux(reindexedActivities);
    }
  };

  return (
    <>
      <Header matrixId={matrixId} handleSubmit={handleSubmit} />
      <Procedures
        envImpact={envImpact}
        setEnvImpact={setEnvImpact}
        envImpactAux={envImpactAux}
        setEnvImpactAux={setEnvImpactAux}
      />
      <div className="card-body card shadow">
        {activitiesAux.map((activity) => (
          <Activity
            key={activity.indexActivity}
            indexActivity={activity.indexActivity}
            deleteActivity={deleteActivity}
            setActivitiesAux={setActivitiesAux}
            activitiesAux={activitiesAux}
            activity={activity}
            envImpact={envImpact}
            setEnvImpactAux={setEnvImpactAux}
            activities={activities}
            setActivities={setActivities}
            envImpactAux={envImpactAux}
          />
        ))}
        <div>
          <button
            type="button"
            className="btn btn-info btn-sm mb-2"
            onClick={addActivity}
          >
            <i className="fas fa-plus mr-1"></i>
            Agregar
          </button>
          <span className="ml-2"> Agregar Actividad</span>
        </div>
      </div>
    </>
  );
};

export default FormEdit;
